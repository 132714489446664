<template>
    <div class="review-main" v-if="translates && translates[langUrl]">
        <div class="review-main__head">
            <div class="review-main__head__user">
                <div v-if="review.user && review.user.avatar" class="review-main__head__user__avatar" :style="`background-image: url(${review.user ? imageSrc(review.user.avatar) : ''})`"></div>
                <div v-else class="review-main__head__user__avatar" :style="`background-image: url(${review.externalUser ? imageSrc(review.externalUser.avatar) : ''})`"></div>
                <!-- <div v-else class="review-main__head__user__avatar"></div> -->
                <div style="display: flex; flex-direction: column">
                    <div style="display: flex">
                        <div class="review-main__head__user__name">
                            <template v-if="review.user">
                                {{ review.user.firstname }} {{ review.user.lastname ? review.user.lastname[0] : '' }}.
                            </template>
                            <template v-else>
                                {{ review.externalUser.firstname }} {{ review.externalUser.lastname ? review.externalUser.lastname[0] : '' }}.
                            </template>
                        </div>
                        <div class="review-main__time">{{ review.date | moment('DD.MM.YYYY') }}</div>
                    </div>
                    <div style="display: flex; align-items: center; margin-top: 5px;">
                        <div class="review-main__head__user__raiting">{{ review.rating.overall }}</div>
                        <v-rating
                            :value="review.rating.overall"
                            background-color="grey"
                            color="warning"
                            half-increments
                            hover
                            length="5"
                            size="15"
                            dense
                            readonly
                            style="margin-left: 5px"
                        />
                    </div>
                </div>
            </div>
            <div class="review-main__head__trip">{{ review.trip.name }}</div>
        </div>
        <div class="review-main__text-container"></div>
        <div class="review-main__text"
        >"{{ review.text }}"</div>
        <!-- <a v-if="review.text.length > 240 && isShort" class="review-main__more" @click="showFullText">{{ translates[langUrl].button_show[lang] }}</a>
        <a v-if="review.text.length > 240 && !isShort" class="review-main__more" @click="showFullText">{{ translates[langUrl].button_hide[lang] }}</a> -->
        <!-- <div
            v-if="review && review.proof && review.proof.picture"
            class="review-main__photos__picture"
            style="margin-top: 32px"
            :style="review.proof.picture ? `background-image: url(${ imageSrc(review.proof.picture) })` : ''">
        </div> -->
        <div v-if="review && review.photos" class="review-main__photos">
            <Splide
                v-if="review && review.photos && review.photos.length > 4 && isDesktop"
                style="margin-top: 30px;"
                :options="{ rewind: true, type: 'loop', perPage: 4, perMove: 1, pagination: false }">
                <template v-for="(photo, index) in review.photos">
                    <template>
                        <SplideSlide 
                            :key="index">
                            <div
                                class="review-main__photos__picture">
                                <img class="review-main__photos__picture__img" :src="imageSrc(photo)" />
                            </div>
                        </SplideSlide>
                    </template>
                </template>
            </Splide>
            <div v-else class="review-main__photos__container">
                <template v-for="(photo, index) in review.photos">
                    <div
                        :key="'photo' + index"
                        class="review-main__photos__picture no-splide">
                        <img class="review-main__photos__picture__img" :src="imageSrc(photo)" />
                    </div>
                </template>
            </div>
        </div>
        <div v-if="review && review.pictures && review.pictures.length > 0" class="review-main__photos">
            <Splide
                v-if="review && review.pictures && review.pictures.length > 4"
                style="margin-top: 30px;"
                :options="{ rewind: true, perPage: 4, perMove: 1, pagination: false }">
                <template v-for="(photo, index) in review.pictures">
                    <template>
                        <SplideSlide
                            :key="index">
                            <div
                                class="review-main__photos__picture">
                                <img :src="imageSrc(photo)" />
                            </div>
                        </SplideSlide>
                    </template>
                </template>
            </Splide>
            <div v-else class="review-main__photos__container">
                <template v-for="(photo, index) in review.pictures">
                    <div
                        :key="'photo' + index"
                        class="review-main__photos__picture no-splide">
                        <img class="review-main__photos__picture__img" :src="imageSrc(photo)" />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';
    import { isMobile } from '../../helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';

    export default {
        name: 'ReviewMain',
        props: {
            review: {
                type: Object,
                default: () => {}
            }
        },
        components: {
            Splide,
            SplideSlide
        },
        data: () => ({
            imageSrc,
            isShort: true,
            langUrl: '/review/name',
            isDesktop: false
        }),
        updated() {
            this.isDesktop = !isMobile
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('reviews', ['entities']),
        },
        methods: {
            // calculateRating(name) {
            //     let arrRating = this.entities.map(function(entity){
            //         return entity.rating[name]
            //     })
            // },
            showFullText() {
                this.isShort = !this.isShort;
            }
        }
    }
</script>


<style lang="scss">
.review-main {
    padding: 40px;
    box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.3);
    border-radius: 12px;
    width: 620px;
    // height: 570px;
    margin-bottom: 40px;
    @media all and(max-width: 768px) {
        width: 100%;
        padding: 20px;
        height: auto;
    }
    &__time{
        margin-left: 12px;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #93A3B4;
    }
    &__head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media all and(max-width: 768px) {
            flex-direction: column;
        }
        &__user {
            display: flex;
            flex-direction: row;
            align-items: center;
            // justify-content: space-between;
            margin-bottom: 15px;
            @media all and(max-width: 768px) {
                margin-bottom: 15px;
            }
            &__avatar{
                width: 50px;
                height: 50px;
                border-radius: 12px 12px 0px 0px;
                border-radius: 50%;
                background-color: #F6F7F9;
                // background: url(../../assets/Ellipse3.png);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                margin-right: 20px;
            }
            &__name {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: #3A4256;

            }
            &__raiting {
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #707378;
                // margin-top: 5px;
            }
        }
        &__trip {
            display: flex;
            max-width: 200px;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #93A3B4;
            @media all and(max-width: 768px) {
                max-width: 100%;
                margin-bottom: 20px;
            }
        }
    }
    
    &__text{
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #3A4256;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 50;
        -webkit-box-orient: vertical;
        &.short {
            -webkit-line-clamp: 4;
            // max-height: 110px;
        }
    }
    &__more {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #E61E4D;
        margin-bottom: 30px;
    }
    &__photos {
        height: 168px;
        margin-top: 32px;
        &__container {
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
        }
        &__picture {
            display: flex;
            align-items: flex-end;
            width: 125px;
            height: 168px;
            border-radius: 12px;
            &.no-splide {
                margin-right: 12px;
                &:last-of-type {
                    margin-right: 0;
                }
            }
            &__img {
                max-height: 100%;
                width: 100%;
                border-radius: 12px;
            }
        }
    }
}
</style>